// ** React Imports
import React, { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '../locales/en.json'
import messagesDe from '../locales/de.json'
import messagesFr from '../locales/fr.json'
import messagesPt from '../locales/pt.json'
import messagesVi from '../locales/vi.json'
import messagesCn from '../locales/cn.json'
import messagesKr from '../locales/kr.json'
import messagesMy from '../locales/my.json'
import messagesPh from '../locales/ph.json'
import messagesLao from '../locales/la.json'
import messagesCambodia from '../locales/km.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn },
  de: { ...messagesDe },
  fr: { ...messagesFr },
  pt: { ...messagesPt },
  vi: { ...messagesVi },
  cn: { ...messagesCn },
  kr: { ...messagesKr },
  my: { ...messagesMy },
  ph: { ...messagesPh },
  la: { ...messagesLao },
  km: { ...messagesCambodia },
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  const DEFAULT_LANGUAGE = 'km';
  // ** States
  const [locale, setLocale] = useState(window.localStorage.getItem('lang') || DEFAULT_LANGUAGE)
  const [messages, setMessages] = useState(menuMessages[locale])

  // ** Switches Language
  const switchLanguage = lang => {
    window.localStorage.setItem('lang', lang)
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={DEFAULT_LANGUAGE}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
