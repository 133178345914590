import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  WifiOutlined,
  PayCircleOutlined,
  RightOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import { Typography, Modal, message, Image, Input } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _cardbg from "../../assets/card.png";
import card_logo1 from "../../assets/card_logo1.jpg";
import card_logo2 from "../../assets/card_logo2.jpg";
import card_logo3 from "../../assets/card_logo3.jpg";
import card_logo4 from "../../assets/card_logo4.jpg";
import card_logo5 from "../../assets/card_logo5.jpg";
import card_logo6 from "../../assets/card_logo6.jpg";
import api from "../../api";
import useNotification from "../../hooks/useNotification";
import * as actions from "../../redux/actions/auth";
import connectCSKH from "../../utils/connectCSKH";
import CurrencyInput from "react-currency-input-field";
import "./Wallet.scss";
import moment from "moment";
import _ from "lodash";
import { currencyFormatVND } from "../../utils/CurrencyFormat";
import { useIntl } from "react-intl";
const LOAN_FEE_RATE = 5;
function getIntValue(value) {
  if (isNaN(value)) {
    return 0
  };
  if (!value) {
    return 0
  }
  return value * 1
}
export default function Wallet() {
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  const { profile } = useSelector((state) => state._auth);
  const { notifications, pushNotifications } = useNotification();
  const dispatch = useDispatch();
  const [currentRequest, setCurrentRequest] = useState({});
  const [OTP, setOTP] = useState("");
  const [visibleOTP, setVisibleOTP] = useState(false);
  const [systemConfig, setSystemConfig] = useState({})
  async function getSystemConfig() {
    const { data } = await api.get("/systemConfiguration/getSystemConfig");
    setSystemConfig(data.data)
  }
  
  useEffect(() => {
    loadProfile();
    getSystemConfig();
    loadContract();
  }, [notifications]);

  const loadProfile = async () => {
    const { data } = await api.get("/users/profile");
    dispatch(actions.initialLogin(data.data));
    dispatch({ type: "UPDATE_KYC", payload: { ...(data?.data?.kyc || {}) } });
    loadCurrentRequest();
    console.log(profile?.kyc?.bank?.bankCardImageUrl)
  };

  const loadCurrentRequest = async () => {
    try {
      const { data } = await api.get("/requests/lasted");
      setCurrentRequest(data?.data ? data.data : {});
    } catch (error) {
      setCurrentRequest({});
      console.log(error);
    }
  };

  const [contract, setContract] = useState({});
  const [visible, setVisible] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [amountToRequest, setAmountToRequest] = useState(0);

  const onConfirmWithdraw = async (e) => {
    if (!currentRequest) {
      message.error(translate('Wallet.label1'));
      return;
    }

    if (profile.balance === 0) {
      message.error(translate('Wallet.label2'));
      return;
    }

    setAmountToRequest(e);
    setVisibleOTP(true);
  };

  async function loadContract() {
    const contracts = await api.get("/contracts");
    if (contracts.data.data[0]) {
      setContract(contracts.data.data[0]);
    }
  }

  async function handleRequest() {
    await loadProfile();
    
    setOTP("")
    if (+profile?.otp !== +OTP) {
      message.error(translate('Wallet.label3'));
      return;
    }

    const contracts = await api.get("/contracts");
    console.log(contracts)
    if (contracts.data.data[0]) {
      const requestResult = await api.post("/requests", {
        amount: amountToRequest,
        contractId: contracts.data.data[0]._id,
        bank_reciever: {
          name: profile?.kyc?.bank.name,
          number: profile?.kyc?.bank.number,
          bankName: profile?.kyc?.bank.bankName,
        },
      });
      setContract(requestResult.data);
      pushNotifications({
        message: `${profile.phone} ${translate('Wallet.label4')}`,
        description: `${translate('Wallet.label5')} ${profile.balance.toLocaleString()}`,
      });
      loadProfile();
      setVisible(true);
      setIsDone(requestResult.data.response === "accepted");

      return requestResult;
    } else {
      message.error(
        translate('Wallet.label6')
      );
      return;
    }
  }
  const renderStatus = (status) => {
    switch (status) {
      case "pending":
        return translate('Wallet.label31');
      case "accepted":
        return translate('Wallet.label32');
      case "rejected":
        return translate('Wallet.label33');
      case "onhold":
        return translate('Wallet.label34');
    }
    return "";
  };
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <div />
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, color: "#fff" }}
        >
          {translate('Wallet.label7')}
        </Typography.Text>
        <div></div>
      </div>
      <div style={{ padding: "4px 10px" }}>
        <Card
          data={profile}
          balance={profile?.balance}
          onWithdraw={(e) => onConfirmWithdraw(e)}
          currentRequest={currentRequest}
        />
        <motion.div
          whileTap={{ scale: 0.97, opacity: 0.3 }}
          className={
            currentRequest && currentRequest.status === "pending"
              ? "disabled_custom"
              : ""
          }
        >
          <Item
            text={translate('Wallet.label8')}
            icon={<PayCircleOutlined className="pay-circle" />}
            onClick={
              // profile?.kyc?.status
                // ? 
                () => onConfirmWithdraw(profile?.balance)
                // : () => message.info(translate('Wallet.label9'))
            }
          />
        </motion.div>
        {!_.isEmpty(currentRequest) && (
          <div className="request-detail">
            <div className="title-detail">{translate('Wallet.label10')}</div>
            <table>
              <tr>
                <td>{translate('Wallet.label11')}</td>
                <td>
                  {moment(currentRequest.created_at).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </td>
              </tr>
              <tr>
                <td>{translate('Wallet.label12')}</td>
                <td>
                  {currentRequest.amount
                    ? currentRequest.amount.toLocaleString() + " " + translate('systemCurrency')
                    : ""}
                </td>
              </tr>
              <tr>
                <td>{translate('Wallet.label13')}</td>
                <td>{renderStatus(currentRequest.status)}</td>
              </tr>
              <tr>
                <td>{translate('Wallet.label14')}</td>
                <td>{currentRequest.error}</td>
              </tr>
            </table>
          </div>
        )}
      </div>
      <div class="grid-container">
  <div class="grid-item"><img class="grid-item-image" src={card_logo1}></img></div>
  <div class="grid-item"><img class="grid-item-image" src={card_logo2}></img></div>
  <div class="grid-item"><img class="grid-item-image" src={card_logo3}></img></div>
  <div class="grid-item"><img class="grid-item-image" src={card_logo4}></img></div>
  <div class="grid-item"><img class="grid-item-image" src={card_logo5}></img></div>
  <div class="grid-item"><img class="grid-item-image" src={card_logo6}></img></div>
</div>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={true}
        onCancel={() => {
          setVisible(false);
        }}
        destroyOnClose
      >
        <div className="verifying">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {isDone ? (
              <>
                <CheckOutlined style={{ color: "#40eb31", fontSize: 40 }} />
                <Typography.Text
                  style={{
                    color: "#333",
                    fontSize: 19,
                    textAlign: "center",
                    paddingVertical: 10,
                  }}
                >
                  {translate('Wallet.label15')}
                </Typography.Text>
                <Typography.Text
                  style={{ color: "#777", fontSize: 15, textAlign: "center" }}
                >
                  {translate('Wallet.label16')}
                </Typography.Text>
              </>
            ) : (
              <>
                {contract?.response == "accepted" ? (
                  <>
                    <CheckCircleOutlined
                      style={{ fontSize: 50, color: "green" }}
                    />
                    <Typography.Text
                      style={{
                        color: "#777",
                        fontSize: 17,
                        color: "green",
                        textAlign: "center",
                        padding: 10,
                      }}
                    >
                      {contract.status == "pending"
                        ? translate('Wallet.label18')
                        : contract?.response}
                    </Typography.Text>
                  </>
                ) : (
                  <>
                    <ExclamationCircleOutlined
                      style={{ fontSize: 50, color: "#eb314a" }}
                    />
                    <Typography.Text style={{ color: "#777", fontSize: 17 }}>
                      {translate('Wallet.label17')}
                    </Typography.Text>
                    <Typography.Text
                      style={{ color: "#777", fontSize: 17, color: "#eb314a" }}
                    >
                      {contract.status == "pending"
                        ? translate('Wallet.label18')
                        : contract?.response}
                    </Typography.Text>
                  </>
                )}

                <Typography.Text style={{ color: "#777", fontSize: 17 }}>
                  {translate('Wallet.label19')}
                </Typography.Text>
                <br />

                <motion.div
                  whileTap={{ opacity: 0.4, scale: 0.97 }}
                  style={{
                    background: "#a11c34",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    padding: 5,
                  }}
                  onClick={connectCSKH}
                >
                  <Typography.Text
                    style={{ fontSize: 17, color: "#fff", fontWeight: "bold" }}
                  >
                    {translate('Wallet.label20')}
                  </Typography.Text>
                </motion.div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        visible={visibleOTP}
        title={"OTP"}
        footer={null}
        closable={true}
        onCancel={() => setVisibleOTP(false)}
        destroyOnClose
      >
        <div className="withdraw-money-container">
          <p>{translate("Wallet.label35")}</p>
          <p>{translate("Wallet.label36")}</p>
          <p>{translate("Wallet.label37")}</p>
          <p>{ (contract && contract.amount > 0)
          ? translate("Wallet.label38").replace('{feeAmount}', contract.amount * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', contract.amount).replace('{totalAmount}', contract.amount + contract.amount * LOAN_FEE_RATE / 100)
          : translate("Wallet.label38").replace('{feeAmount}', 0 * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', 0).replace('{totalAmount}', 0 + 0 * LOAN_FEE_RATE / 100)
        }</p>
          <p>{ (contract && contract.amount > 0)
          ? translate("Wallet.label39").replace('{feeAmount}', contract.amount * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', contract.amount).replace('{totalAmount}', contract.amount + contract.amount * LOAN_FEE_RATE / 100)
          : translate("Wallet.label39").replace('{feeAmount}', 0 * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', 0).replace('{totalAmount}', 0 + 0 * LOAN_FEE_RATE / 100)
        }</p>
          <p>{(contract && contract.amount > 0) 
          ? translate("Wallet.label40").replace('{feeAmount}', contract.amount * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', contract.amount).replace('{totalAmount}', contract.amount + contract.amount * LOAN_FEE_RATE / 100)
          : translate("Wallet.label40").replace('{feeAmount}', 0 * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', 0).replace('{totalAmount}', 0 + 0 * LOAN_FEE_RATE / 100)
        }</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              className="check-amount"
              onClick={() => {
                window.open(`${systemConfig?.telegramUrl}`, "_blank")
              }}
            >
              <Typography.Text
                style={{ color: "#fff", fontWeight: 700, fontSize: 16, textAlign: 'center' }}
              >
                {translate('Wallet.label41')}
              </Typography.Text>
            </motion.div>
          </div>
          <p></p>
          <Input
            className="input-currency"
            placeholder={translate('Wallet.label21')}
            value={OTP}
            onChange={(e) => setOTP(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              className="check-amount"
              onClick={() => {
                setVisibleOTP(false);
                handleRequest();
              }}
            >
              <Typography.Text
                style={{ color: "#fff", fontWeight: 700, fontSize: 16 }}
              >
                {translate('Wallet.label22')}
              </Typography.Text>
            </motion.div>
          </div>
        </div>
      </Modal>
    </motion.div>
  );
}

function Card({ data, balance, onWithdraw, currentRequest }) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState(0);
  const [showMoney, setShowMoney] = useState(true);
  const [showBankAccount, setShowBankAccount] = useState(false);
  const intl = useIntl();
  
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "4px" }}>
        {translate('Wallet.label23')}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data?.kyc?.bank?.bankCardImageUrl
          ? <img src={data?.kyc?.bank?.bankCardImageUrl} style={{
            borderRadius: 5,
            width: "100%",
            height: 250,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }} />
          :
          <div
            style={{
              borderRadius: 5,
              width: "100%",
              height: 250,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              backgroundImage: `url(${_cardbg})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="card-head-img">
              <div style={{}}>
                {/* {data?.kyc?.bank?.logo ? (
                <Image
                  src={data?.kyc?.bank?.logo}
                  width="50%"
                  preview={false}
                />
              ) : ( */}
                {/* <WifiOutlined
                  style={{
                    fontSize: 26,
                    color: "#fff",
                    fontWeight: "bold",
                    transform: "rotate(90deg)",
                  }}
                /> */}
                <Typography.Text style={{ fontSize: 24, fontWeight: "bold", color: '#fff', padding: '0px 5px' }}>
                  {data?.kyc?.bank?.bankName}
                </Typography.Text>
                {/* )} */}
              </div>
            </div>

            <div
              style={{
                padding: 10,
                justifyContent: "flex-start",
                minWidth: "100%",
              }}
            >
              <div
                className="atm-card-information"
                style={{ position: "relative" }}
              >
                {data?.kyc?.bank?.number ? (
                  <>
                    {/* <div style={{ position: "absolute", top: 0, right: "10px" }}>
                    {showBankAccount ? (
                      <EyeOutlined
                        onClick={() => setShowBankAccount((prev) => !prev)}
                        className="eye-icon"
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        onClick={() => setShowBankAccount((prev) => !prev)}
                        className="eye-icon"
                      />
                    )}
                  </div> */}

                    {/* <Typography.Text className="atm-card-text">
                    {data?.kyc?.bank?.number
                      ? showBankAccount
                        ? `${data.kyc.bank.number}`
                        : `********` +
                          data.kyc.bank.number.substring(
                            data.kyc.bank.number.length - 4
                          )
                      : ""}
                  </Typography.Text> */}
                    <Typography.Text className="atm-card-text">
                      {data?.kyc?.bank?.name}
                    </Typography.Text>
                  </>
                ) : (
                  <Typography.Text className="atm-card-text">
                    {translate('Wallet.label24')}
                  </Typography.Text>
                )}
              </div>
            </div>
          </div>
        }
      </div>
      <p style={{ margin: "4px 0", color: "#1890ff", fontSize: "12px" }}>
        {translate('Wallet.label25')}
      </p>
      <motion.div
        className="check-amount-container"
        onClick={() => {
          if (currentRequest && currentRequest.status === "pending") {
            setVisible(true);
            return;
          }
          setVisible(true); // bỏ sau
        }}
      >
        <motion.div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          whileTap={{ opacity: 0.3 }}
        >
          <Typography.Text
            style={{ fontSize: 15, color: "#333", fontWeight: "600" }}
          >
            {translate('Wallet.label26')}
          </Typography.Text>
          {showMoney ? (
            <EyeOutlined
              onClick={(e) => {
                setShowMoney((prev) => !prev);
                e.stopPropagation();
              }}
              className="eye-icon"
            />
          ) : (
            <EyeInvisibleOutlined
              onClick={(e) => {
                setShowMoney((prev) => !prev);
                e.stopPropagation();
              }}
              className="eye-icon"
            />
          )}
        </motion.div>
        <div>
          <Typography.Text
            style={{ fontSize: 17, color: "#CE4F53", fontWeight: 700, display: "block" }}
          >
            {showMoney ? `${currencyFormatVND(getIntValue(data?.balance))}  ${translate('systemCurrency')}` : "******"}{" "}
          </Typography.Text>
        </div>
      </motion.div>
      {/* <span
        onClick={() => history.push("/history")}
        style={{ textDecoration: "underline", margin: 5 }}
      >
        Biến động số dư
      </span> */}
      <Modal
        visible={visible}
        title={translate('Wallet.label27')}
        footer={null}
        closable={true}
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <div className="withdraw-money-container">
          <CurrencyInput
            className="input-currency"
            min={0}
            max={balance}
            placeholder={translate('Wallet.label28')}
            decimalsLimit={2}
            onValueChange={(value, name) => setAmount(parseInt(value))}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              className="check-amount"
              onClick={() => {
                if (amount > data?.balance) {
                  message.info(translate('Wallet.label29'));
                  return;
                }

                setVisible(false);
                onWithdraw(amount);
              }}
            >
              <Typography.Text
                style={{ color: "#fff", fontWeight: 700, fontSize: 16 }}
              >
                {translate('Wallet.label30')}
              </Typography.Text>
            </motion.div>
          </div>
        </div>
      </Modal>
    </motion.div>
  );
}
const Item = ({ text = "title", icon, onClick = () => { } }) => {
  return (
    <div className="item" onClick={onClick}>
      <PayCircleOutlined style={{ fontSize: 22, color: "#1890ff" }} />
      <Typography.Text style={{ flex: 1, fontSize: 16, paddingLeft: 20 }}>
        {text}
      </Typography.Text>
      <RightOutlined style={{ fontSize: 16 }} />
    </div>
  );
};
