import moment from "moment"
import logo_contract from "../assets/logo_contract.png"
import signature from "../assets/signature.jpg"
import { currencyFormatVND } from "../utils/CurrencyFormat"
import { useIntl } from "react-intl";
import React, { useState, useEffect, useCallback } from 'react';

function ContractFormNew(props) {
    const intl = useIntl();
    const translate = useCallback(
      (id) => {
        return intl.formatMessage({ id })
      },
      [intl]
    )
    const { contract = {}, profile = {}, currentContract } = props
    const benA = {
        title: translate("DetailContract.label1"),
        des: [
            {
                title: translate("DetailContract.label2"),
                value: translate("DetailContract.label3")
            }, {
                title: translate("DetailContract.label4"),
                value: translate("DetailContract.label5")
            }, {
                title: translate("DetailContract.label6"),
                value: translate("DetailContract.label7")
            }, {
                title: translate("DetailContract.label8"),
                value: translate("DetailContract.label9")
            }, {
                title: translate("DetailContract.label10"),
                value: translate("DetailContract.label11"),
            }, {
                title: translate("DetailContract.label12"),
                value: translate("DetailContract.label13"),
            }, {
                title: translate("DetailContract.label14"),
                value: translate("DetailContract.label15"),
            }, {
                title: translate("DetailContract.label16"),
                value: translate("DetailContract.label17"),
            }, {
                title: translate("DetailContract.label18"),
                value: translate("DetailContract.label19"),
            }, {
                title: translate("DetailContract.label20"),
                value: translate("DetailContract.label21"),
            }
        ]
    }

    const sugession = {
        title: translate("DetailContract.label22"),
        des: [
            translate("DetailContract.label23"),
            translate("DetailContract.label24"),
            translate("DetailContract.label25"),
            translate("DetailContract.label26"),
            translate("DetailContract.label27"),
        ]
    }

    const articles1 = {
        title: translate("DetailContract.label28"),
        des: [
            translate("DetailContract.label29"),
            translate("DetailContract.label30"),
            translate("DetailContract.label31"),
            translate("DetailContract.label32"),
        ]
    }
    const articles2 = [
        translate("DetailContract.label33"),
        translate("DetailContract.label34"),
        translate("DetailContract.label35"),
        translate("DetailContract.label36"),
        translate("DetailContract.label37"),
        translate("DetailContract.label38"),
    ]
    const articles3a = [
        translate("DetailContract.label39"),
        translate("DetailContract.label40"),
        translate("DetailContract.label41"),
        translate("DetailContract.label42"),
    ]
    const articles3b = [
        translate("DetailContract.label43"),
        translate("DetailContract.label44"),
        translate("DetailContract.label45"),
        translate("DetailContract.label46"),
        translate("DetailContract.label47"),
        translate("DetailContract.label48"),
    ]
    const articles4 = {
        des: translate("DetailContract.label49"),
        note: translate("DetailContract.label50"),
        content: [
            translate("DetailContract.label51"),
            translate("DetailContract.label52"),
            translate("DetailContract.label53"),
            translate("DetailContract.label54"),
            translate("DetailContract.label55"),
            translate("DetailContract.label56"),
            translate("DetailContract.label57"),
            translate("DetailContract.label58"),
        ],
        commit: translate("DetailContract.label59"),
        agree: translate("DetailContract.label60"),
    }


    return (
        <div style={{ fontSize: "12px" }}>
            <div style={{ textAlign: "center" }}>
                <img src={logo_contract} alt="" width={"70%"} height={"auto"} />
            </div>
            <h2 style={{ textAlign: "center", fontWeight: "800", marginTop: "20px" }}>{translate("DetailContract.label61")}</h2>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>{translate("DetailContract.label62")}</h4>
            <table style={{ width: "100%", borderCollapse: "collapse", fontSize: "11px" }}>
                <tr>
                    <td style={{ border: "1px solid", width: "45%", fontWeight: "600" }}>{translate("DetailContract.label63")}</td>
                    <td style={{ border: "1px solid", width: "55%" }}><span style={{ padding: "0 4px" }}>{profile?.kyc?.name}</span></td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid", width: "45%", fontWeight: "600" }} >{translate("DetailContract.label64")}</td>
                    <td style={{ border: "1px solid", width: "55%" }}><span style={{ padding: "0 4px" }}>{profile?.phone}</span></td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid", width: "45%", fontWeight: "600" }} >{translate("DetailContract.label65")}</td>
                    <td style={{ border: "1px solid", width: "55%" }}><span style={{ padding: "0 4px" }}>{profile?.kyc?.id_number}</span></td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid", width: "45%", fontWeight: "600" }} >{translate("DetailContract.label66")}</td>
                    <td style={{ border: "1px solid", width: "55%" }}><span style={{ padding: "0 4px" }}>{contract?.amount || currentContract?.amount ? currencyFormatVND(contract?.amount || currentContract?.amount) + ` ${translate("systemCurrency")}` : ""}</span></td>
                </tr>
            </table>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>
                {benA.title}
            </h4>
            {
                benA.des.map(item =>
                    <p style={{ display: "flex", marginBottom: "0px", gap: "4px" }}>
                        <span style={{ minWidth: "100px" }}>{item.title}:</span>
                        <span>{item.value}</span>
                    </p>)
            }

            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>
                {translate("DetailContract.label67")}
            </h4>
            <p style={{ display: "flex", marginBottom: "0px", gap: "4px", fontWeight: "600" }}>
                <span style={{ minWidth: "100px" }}>{translate("DetailContract.label68")}</span>
                <span>{translate("DetailContract.label69")}</span>
            </p>
            <p style={{ display: "flex", marginBottom: "0px", gap: "4px", fontWeight: "600" }}>
                <span style={{ minWidth: "100px" }}>{translate("DetailContract.label70")}</span>
                <span >{currentContract?.times ? moment().format("DD/MM/YYYY") : contract?.created_at ? moment(contract?.created_at).format("DD/MM/YYYY") : ""}</span>
            </p>
            <p style={{ display: "flex", marginBottom: "0px", gap: "4px", fontWeight: "600" }}>
                <span style={{ minWidth: "100px" }}>{translate("DetailContract.label71")}</span>
                <span >{currentContract?.times ? currentContract?.times + ` ${translate("DetailContract.label72")}` : contract?.times ? contract?.times + ` ${translate("DetailContract.label72")}` : ""}</span>
            </p>

            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>{sugession.title}</h4>
            <div>
                {
                    sugession.des.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        {index + 1} {item}
                    </p>)
                }
            </div>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>{translate("DetailContract.label73")}</h4>
            <h4 style={{ fontWeight: "600" }}>{articles1.title}</h4>
            <div>
                {
                    articles1.des.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        1.{index + 1} {item}
                    </p>)
                }
            </div>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>{translate("DetailContract.label74")}</h4>
            <div>
                {
                    articles2.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        2.{index + 1} {item}
                    </p>)
                }
            </div>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>{translate("DetailContract.label75")}</h4>
            <h4 style={{ fontWeight: "600" }}> {translate("DetailContract.label76")}</h4>
            <div>
                {
                    articles3a.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        3.{index + 1} {item}
                    </p>)
                }
            </div>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>{translate("DetailContract.label77")}</h4>
            <div>
                {
                    articles3b.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        3.{index + 1 + articles3a.length} {item}
                    </p>)
                }
            </div>

            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>{translate("DetailContract.label78")}</h4>
            <p style={{ textAlign: "justify" }}>{articles4.des}</p>
            <p>{articles4.note}:</p>
            <div>
                {
                    articles4.content.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        {index + 1}{")"} {item}
                    </p>)
                }
            </div>
            <p style={{ fontWeight: "600", marginTop: "12px", textAlign: "justify" }}>{articles4.commit}</p>
            <p style={{ fontWeight: "600", marginTop: "12px", textAlign: "justify" }}>{articles4.agree}</p>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{ width: "45%", textAlign: "center", fontWeight: "600", fontSize: "10px" }}>
                    <p>{translate("DetailContract.label79")}</p>
                    {
                        contract?.signature_capture
                        && <>
                            <img src={contract?.signature_capture} alt="" width={"100%"} />
                            <p>{profile?.kyc?.name}</p>
                        </>
                    }
                </div>
                <div style={{ width: "55%", textAlign: "center" }}>
                    <img src={signature} alt="" width={"100%"} />
                </div>
            </div>

        </div >
    );
}

export default ContractFormNew;