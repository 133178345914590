import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'antd/dist/antd.css';
import { IntlProviderWrapper } from './helper/Internationalization';

ReactDOM.render(
  <React.StrictMode>
    <IntlProviderWrapper>
    <App />
    </IntlProviderWrapper>
  </React.StrictMode>,
  document.getElementById('app')
);
