import React, { useEffect, useState, useCallback } from "react";
import { CreditCard } from "../../components";
import { motion } from "framer-motion";
import { Input, Select, Typography, Image, message, Button, Spin } from "antd";
import _bank from "./bank.json";
import { isNotEmptyString } from "../../utils/isEmptyString";
import { useIntl } from "react-intl";
import { CameraOutlined, DeleteOutlined } from "@ant-design/icons";
/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import Compress from "react-image-file-resizer";
import converter from "../../utils/converterBase64ToBinary";
import "./verify.scss";
import { BASE_URL } from "../../utils/constant";

const Camera = ({ type, setImage, image, profile, isVerified }) => {
  console.log(`image ${image}`)
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  function onChange(event) {
    if (event.target && event.target.files[0]) {
      Compress.imageFileResizer(
        event.target.files[0],
        480,
        480,
        "PNG", // compress format WEBP, JPEG, PNG
        100,
        0, // rotation
        async (uri) => {
          try {
            let formData = new FormData();
            const dataURI = converter(uri);
            formData.append("file", dataURI);
            setLoading(true);
            const { data } = await axios.post(BASE_URL + "/upload", formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            });

            // const transformater = 'w_300,h_400,c_fit';
            // const splited = data.secure_url.split('/');
            // let str = [
            //   ...splited.slice(0, 6),
            //   transformater,
            //   ...splited.slice(6),
            // ];

            const endpoint = data.secure_url;
            console.log(endpoint)
            localStorage.setItem(type, endpoint);
            setImage(endpoint);
          } catch (err) {
            message.error(translate('UserInfo.label1'));
          } finally {
            setLoading(false);
          }
        },
        "base64" // blob or base64 default base64
      );
    }
  }

  return (
    <motion.div
      className="image-container"
      style={{
        backgroundImage: `url(${image})`,
        position: "relative",
      }}
      whileTap={{ scale: 0.98, opacity: 0.3 }}
      onClick={() => !image && document.getElementById(`camera${type}`).click()}
    >
      {
        image && isVerified !== 1 ?
          <DeleteOutlined
            style={{
              padding: "8px",
              backgroundColor: "#FFF",
              borderRadius: "8px",
              position: "absolute",
              top: "8px",
              right: "8px",
              fontSize: "16px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
            onClick={(event) => {
              event.stopPropagation();
              setImage(null);
            }}
          /> : ''
      }
      {loading ? (
        <Spin spinning />
      ) : (
        <>
          {!image && (
            <CameraOutlined
              style={{ fontSize: 30, color: image ? "#fff" : "#333" }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={onChange}
            id={`camera${type}`}
            hidden
          />
          {type == "bankCardImage" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {translate('BankAccountInfo.label6')}
            </Typography.Text>
          )}
          {type == "front" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {translate('UserInfo.label2')}
            </Typography.Text>
          )}
          {type == "back" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {translate('UserInfo.label3')}
            </Typography.Text>
          )}
          {type == "face" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>{translate('UserInfo.label4')}</div>
              <div>{translate('UserInfo.label5')}</div>
            </Typography.Text>
          )}
        </>
      )}
    </motion.div>
  );
};

export default function Three({ handleCallApi, profile, isVerified }) {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  const [bankCardImageUrl, setBankCardImageUrl] = useState(null);
  const [state, setState] = useState({
    bankName: "",
    logo: "",
    number: "",
    name: "",
    bankCardImageUrl: "",
  });
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setState({
      bankName: profile?.bank?.bankName,
      logo: profile?.bank?.logo,
      // number: profile?.bank?.number,
      name: profile?.bank?.name,
      bankCardImageUrl: profile?.bank?.bankCardImageUrl,
    });
    setBankCardImageUrl(profile?.bank?.bankCardImageUrl)
    console.log(profile?.bank)
    console.log(isVerified)
    if (isVerified) {
      setDisabled(true);
    }
  }, [profile]);

  async function onConfirm() {
    // setState({ ...state, bankCardImageUrl: bankCardImageUrl })};
    // if (!state.bankName || !state.name 
    //   // || !state.number 
    //   || !bankCardImageUrl) {
    //   console.log(state)
    //   message.error(translate('BankAccountInfo.label1'));
    //   return;
    // }
    let _bankInfo = {
      bank: {
        ...state,
        bankCardImageUrl: bankCardImageUrl
      },
    };

    const verified = {};
    console.log(state)
    if (
      Object.values(state).length >= 1 &&
      Object.values(state).indexOf("") < 0
    ) {
      verified.verifiedBankInfo = 1;
    }
    handleCallApi(_bankInfo, 3, verified);
  }

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="bank-form-container"
    >
      {/* <CreditCard
        logoBank={state?.logo}
        name={state?.name}
        bankNumber={state?.number}
        nameBank={state?.bankName}
        bankDate={state?.date}
      /> */}
      <div style={{ padding: "20px 0", width: "100%" }}>
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {translate('BankAccountInfo.label6')}
        </p>
        <Camera
          type="bankCardImage"
          setImage={setBankCardImageUrl}
          image={bankCardImageUrl}
          profile={profile}
          isVerified={isVerified}
        />
      </div>
      <div style={{ padding: "20px 0", width: "100%" }}>
        {/* <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {translate('BankAccountInfo.label2')}
        </p> */}
        {/* <Select
          className="select-bank"
          placeholder="Tên ngân hàng"
          disabled={isNotEmptyString(profile?.bank?.bankName)}
          options={_bank.banksnapas.map((item) => ({
            value: item.shortName,
            label: (
              <>
                <div>
                  <Typography.Text strong> {item.shortName}</Typography.Text> -{" "}
                  <Typography.Text>{item.vn_name}</Typography.Text>
                </div>
                {item.logo && (
                  <Image src={item.logo} preview={false} width="20%" />
                )}
              </>
            ),
          }))}
          value={state.bankName}
          onChange={(e) => {
            setState({
              ...state,
              bankName: e,
              logo: _bank.banksnapas.find((item) => item.shortName === e)?.logo,
            });
          }}
        ></Select> */}
        {/* {!state?.bankCardImageUrl && (
            <CameraOutlined
              style={{ fontSize: 30, color: state?.bankCardImageUrl ? "#fff" : "#333" }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={onChange}
            id={`camera${type}`}
            hidden
          />

          {!state?.bankCardImageUrl && (
            <Typography.Text
              style={{
                color: state.bankCardImageUrl ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {translate('UserInfo.label2')}
            </Typography.Text>
          )} */}
        {/* <Input
          value={state.bankName}
          onChange={(e) => setState({ ...state, bankName: e.target.value })}
          size="large"
          className="bank-input"
          disabled={isNotEmptyString(profile?.bank?.bankName)}
        /> */}
        {/* <p style={{ marginBottom: "4px", fontSize: "16px", textAlign: "left" }}>
          <span style={{ color: "red" }}>*</span> {translate('BankAccountInfo.label3')}
        </p>
        <Input
          size="large"
          // type="number"
          value={state.number}
          onChange={(e) => {
            const inputValue = e.target.value;
            const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
            setState({ ...state, number: sanitizedValue });
          }}
          // pattern="
          pattern='"[0-9]*'
          className="bank-input"
          disabled={isNotEmptyString(profile?.bank?.number)}
        /> */}

        {/* <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {translate('BankAccountInfo.label4')}
        </p>
        <Input
          value={state.name}
          onChange={(e) => setState({ ...state, name: e.target.value })}
          size="large"
          className="bank-input"
          disabled={isNotEmptyString(profile?.bank?.name)}
        /> */}

        {isVerified !== 1 ? <div className="send-request-div">
          <Button
            className="confirm-btn"
            onClick={onConfirm}
          >
            <Typography.Text className="confirm-div-title">
              {translate('BankAccountInfo.label5')}
            </Typography.Text>
          </Button>
        </div> : ''}
      </div>
    </motion.div>
  );
}
