import React, { useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import { Input, Typography, Select, Form, Button } from "antd";
import "./verify.scss";
import { isNotEmptyString } from "../../utils/isEmptyString";
import { useIntl } from "react-intl";
import { Spin, message } from "antd";
import axios from "axios";
import { CameraOutlined, DeleteOutlined } from "@ant-design/icons";
import Compress from "react-image-file-resizer";
import converter from "../../utils/converterBase64ToBinary";
import { BASE_URL } from "../../utils/constant";
import InputMask from "react-input-mask";
import api from "../../api";
import moment from "moment";


const Camera = ({ type, setImage, image, profile, isVerified }) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  function onChange(event) {
    if (event.target && event.target.files[0]) {
      Compress.imageFileResizer(
        event.target.files[0],
        480,
        480,
        "PNG", // compress format WEBP, JPEG, PNG
        100,
        0, // rotation
        async (uri) => {
          try {
            let formData = new FormData();
            const dataURI = converter(uri);
            formData.append("file", dataURI);
            setLoading(true);
            const { data } = await axios.post(BASE_URL + "/upload", formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            });

            // const transformater = 'w_300,h_400,c_fit';
            // const splited = data.secure_url.split('/');
            // let str = [
            //   ...splited.slice(0, 6),
            //   transformater,
            //   ...splited.slice(6),
            // ];

            const endpoint = data.secure_url;
            localStorage.setItem(type, endpoint);
            setImage(endpoint);
          } catch (err) {
            message.error(translate('UserInfo.label1'));
          } finally {
            setLoading(false);
          }
        },
        "base64" // blob or base64 default base64
      );
    }
  }

  return (
    <motion.div
      className="image-container"
      style={{
        backgroundImage: `url(${image})`,
        position: "relative",
      }}
      whileTap={{ scale: 0.98, opacity: 0.3 }}
      onClick={() => !image && document.getElementById(`camera${type}`).click()}
    >
      {
        image && isVerified !== 1 ?
        <DeleteOutlined
          style={{
            padding: "8px",
            backgroundColor: "#FFF",
            borderRadius: "8px",
            position: "absolute",
            top: "8px",
            right: "8px",
            fontSize: "16px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
          onClick={(event) => {
            event.stopPropagation();
            setImage(null);
          }}
        /> : ''
      }
      {loading ? (
        <Spin spinning />
      ) : (
        <>
          {!image && (
            <CameraOutlined
              style={{ fontSize: 30, color: image ? "#fff" : "#333" }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={onChange}
            id={`camera${type}`}
            hidden
          />

          {type == "front" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {translate('UserInfo.label2')}
            </Typography.Text>
          )}
          {type == "back" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {translate('UserInfo.label3')}
            </Typography.Text>
          )}
          {type == "face" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>{translate('UserInfo.label4')}</div>
              <div>{translate('UserInfo.label5')}</div>
            </Typography.Text>
          )}
        </>
      )}
    </motion.div>
  );
};

export default function Two({ handleCallApi, profile, isVerified }) {
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [face, setFace] = useState(null);
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setFront(profile?.id_front);
    setBack(profile?.id_back);
    setFace(profile?.id_with_face);
    form.setFieldsValue({
      // contact1Name: profile?.contact1Name,
      // contact1PhoneNumber: profile?.contact1PhoneNumber,
      // contact1Relationship: profile?.contact1Relationship,
      // contact2Name: profile?.contact2Name,
      // contact2PhoneNumber: profile?.contact2PhoneNumber,
      // contact2Relationship: profile?.contact2Relationship,
      income: profile?.income,
      job: profile?.job,
      purpose: profile?.purpose,
    });
    if (isVerified) {
      setDisabled(true);
    }
  }, [profile]);

  const onFinish = async (values) => {
    if (!front || !back || !face) {
        message.error(translate('UserInfo.label6'));
        return;
      }

    let _contact = {
      // ...values,
      id_front: front,
      id_back: back,
      id_with_face: face,
    };

    const verified = {};
    if (
      Object.values(_contact).length === 3 &&
      Object.values(_contact).indexOf("") < 0
    ) {
      verified.verifiedContactInfo = 1;
    }
    handleCallApi(_contact, 2, verified);
  };

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="personal-information-container"
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onFinish}
      >
        {/* <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {translate('OtherUserInfo.label1')}
        </p> */}
        {/* <Form.Item
          rules={[
            {
              required: true,
              message: translate('OtherUserInfo.label8'),
            },
          ]}
          name="job"
        >
          <Input
            size="large"
            placeholder={translate('OtherUserInfo.label1')}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.job)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {translate('OtherUserInfo.label2')}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: translate('OtherUserInfo.label8'),
            },
          ]}
          name="income"
        >
          <Input
            size="large"
            placeholder={translate('OtherUserInfo.label2')}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.income)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {translate('OtherUserInfo.label3')}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: translate('OtherUserInfo.label8'),
            },
          ]}
          name="purpose"
        >
          <Input
            size="large"
            placeholder={translate('OtherUserInfo.label3')}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.purpose)}
          />
        </Form.Item> */}
        <p style={{ marginBottom: "4px", fontSize: "16px", textAlign: "left" }}>
          <span style={{ color: "red" }}>*</span> {translate('UserInfo.label22')}
        </p>
        <Camera
          type="front"
          setImage={setFront}
          image={front}
          profile={profile}
          isVerified={isVerified}
        />
        <Camera type="back" setImage={setBack} image={back} profile={profile} isVerified={isVerified}/>
        <Camera type="face" setImage={setFace} image={face} profile={profile} isVerified={isVerified}/>

        { isVerified !== 1 ? <motion.div
          whileTap={{ opacity: 0.4, scale: 0.97 }}
          style={{ display: "flex", justifyContent: "center" }}
          disabled={disabled}
        >
          <Button className="confirm-btn" htmlType="submit">
            <Typography.Text className="btn-title">{translate('OtherUserInfo.label10')}</Typography.Text>
          </Button>
        </motion.div> : '' }
      </Form>
    </motion.div>
  );
}
