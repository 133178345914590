import React, { useState, useCallback } from "react";
import {
  CheckSquareOutlined,
  CreditCardOutlined,
  IdcardOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Spin, Typography } from "antd";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
// import Overview from "./Overview"; //show overview UI
import One from "./One";
import Two from "./Two";
import Three from "./Three";
import api from "../../api";
import Success from "./Success"; //show success UI
import { useDispatch, useSelector } from "react-redux";
import "./verify.scss";
import * as actions from "../../redux/actions/auth";
import { useIntl } from "react-intl";

export default function Verify() {
  const router = useHistory();
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [token] = useState(localStorage.getItem("access_token"));
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state) => state._auth.profile);
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  // async function updateInitRoute(initRoute) {
  //   await api.post('/users/init', { initRoute });
  // }
  // useEffect(() => {
  //   if (step == 1) {
  //     updateInitRoute('/verify');
  //   }
  //   if (step == 4) {
  //     updateInitRoute('/vay');
  //   }
  // }, [step]);

  async function handleCallApi(params, step, verified) {
    setLoading(true);
    await api.post("/users/updateProfile", {
      ...verified,
      kyc: { ...profile?.kyc, ...params },
    });
    step == 1 &&
      (await api.put("/users/profile/avatar", { url: params.id_with_face }));

    reloadProfile();
    setLoading(false);
    setStep(step + 1);
  }

  async function reloadProfile() {
    if (token) {
      const { data } = await api.get("/users/profile");
      dispatch(actions.initialLogin(data.data));
      dispatch({ type: "UPDATE_KYC", payload: { ...(data?.data?.kyc || {}) } });
    }
  }

  async function handleClick() {
    const param = { ...profile?.kyc };
    await api.post("/users/verify", param);
    history.push("/vay");
  }
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <LeftOutlined
          className="arrow-icon"
          onClick={() => (step != 0 ? setStep(0) : router.goBack())}
          style={{ fontSize: "16px" }}
        />{" "}
        <Typography.Text className="header-title">
          {step == 0
            ? translate("MyProfile.label1")
            : step == 1
              ? translate("MyProfile.label2")
              : step == 2
                ? translate("MyProfile.label3")
                : step == 3
                  ? translate("MyProfile.label4")
                  : ""}
        </Typography.Text>
        <div style={{ height: "1px", width: " 10px" }}></div>
      </div>

      {loading ? (
        <div className="loading">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} />} />
          <Typography.Text>{translate("MyProfile.label5")}</Typography.Text>
        </div>
      ) : (
        <>
          {step == 0 && (
            // <Overview
            //   onOk={(e) => {
            //     setStep(1);
            //   }}
            // />
            <>
              <div className="myInfo">
                <div className="myInfoItem" onClick={() => setStep(1)}>
                  <div className="d-flex align-item-center">
                    <IdcardOutlined
                      style={{
                        fontSize: "24px",
                        marginRight: "16px",
                        borderRadius: "50%",
                        padding: "8px",
                        backgroundColor: "blueviolet",
                        color: "white",
                      }}
                    />
                    <div className="info">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ minWidth: "160px" }}> {translate("MyProfile.label6")}</p>
                      </div>
                      <span>{translate("MyProfile.label7")}</span>
                    </div>
                  </div>
                  <RightOutlined />

                  <div style={{ position: "absolute", bottom: "0", right: "0" }}>
                    <span
                      style={{
                        fontSize: "11px",
                        padding: "1px",
                        color: profile?.verifiedPersonalInfo ? "green" : "red",
                        borderRadius: "4px",
                      }}
                    >
                      {profile?.verifiedPersonalInfo ? translate("MyProfile.label8") : translate("MyProfile.label9")}
                    </span>
                  </div>
                </div>
                <div className="myInfoItem" onClick={() => setStep(2)}>
                  <div className="d-flex align-item-center">
                    <IdcardOutlined
                      style={{
                        fontSize: "24px",
                        marginRight: "16px",
                        borderRadius: "50%",
                        padding: "8px",
                        backgroundColor: "red",
                        color: "white",
                      }}
                    />
                    <div className="info">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ minWidth: "160px" }}>{translate("MyProfile.label10")}</p>
                      </div>
                      <span>{translate("MyProfile.label11")}</span>
                    </div>
                  </div>
                  <RightOutlined />
                  <div style={{ position: "absolute", bottom: "0", right: "0" }}>
                    <span
                      style={{
                        fontSize: "11px",
                        padding: "1px",
                        color: profile?.verifiedContactInfo ? "green" : "red",
                        borderRadius: "4px",
                      }}
                    >
                      {profile?.verifiedContactInfo ? translate("MyProfile.label8") : translate("MyProfile.label9")}
                    </span>
                  </div>
                </div>
                <div className="myInfoItem" onClick={() => setStep(3)}>
                  <div className="d-flex align-item-center">
                    <CreditCardOutlined
                      style={{
                        fontSize: "24px",
                        marginRight: "16px",
                        borderRadius: "50%",
                        padding: "8px",
                        backgroundColor: "green",
                        color: "white",
                      }}
                    />
                    <div className="info">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ minWidth: "160px" }}>{translate("MyProfile.label12")}</p>
                      </div>
                      <span>{translate("MyProfile.label13")}</span>
                    </div>
                  </div>
                  <RightOutlined />

                  <div style={{ position: "absolute", bottom: "0", right: "0" }}>
                    <span
                      style={{
                        fontSize: "11px",
                        padding: "1px",
                        color: profile?.verifiedBankInfo ? "green" : "red",
                        borderRadius: "4px",
                      }}
                    >
                      {profile?.verifiedBankInfo ? translate("MyProfile.label8") : translate("MyProfile.label9")}
                    </span>
                  </div>
                </div>
              </div>
              <p className="noteUser">
                {translate("MyProfile.label14")}
              </p>
              <p
                className={`button ${profile?.verifiedPersonalInfo &&
                  profile?.verifiedContactInfo &&
                  profile?.verifiedBankInfo
                  ? ""
                  : "disabled_custom"
                  }`}
                onClick={() => handleClick()}
              >
                {translate("MyProfile.label15")}
              </p>
            </>
          )}
          {step == 1 && (
            <One
              handleCallApi={handleCallApi}
              profile={profile?.kyc}
              isVerified={profile?.verifiedPersonalInfo}
            />
          )}
          {step == 2 && (
            <Two
              handleCallApi={handleCallApi}
              profile={profile?.kyc}
              isVerified={profile?.verifiedContactInfo}
            />
          )}
          {step == 3 && (
            <Three
              handleCallApi={handleCallApi}
              profile={profile?.kyc}
              isVerified={profile?.verifiedBankInfo}
            />
          )}
          {step == 4 && <Success />}
        </>
      )
      }
    </motion.div >
  );
}
